const plugin = require('tailwindcss/plugin');

module.exports = {
    // purge: {
    //     enabled: true,
    //     content: ['./**/*.js'],
    // },
    theme: {
        important: true,
        fontFamily: {
            display: ['Roboto'],
            sans: ['Roboto'],
            serif: ['Roboto'],
            mono: ['Roboto'],
            body: ['Roboto'],
        },
        fontSize: {
            '10': '10px',
            '12': '12px',
            '14': '14px',
            '16': '16px',
            '18': '18px',
            '19': '19px',
            '20': '20px',
            '24': '24px',
            '30': '30px',
            '34': '34px',
            '36': '36px',
            '40': '40px',
            '44': '44px',
            '70': '70px',
            '100': '100px',
        },
        borderRadius: {
            'none': '0',
            'lg': '0.5rem',
            'xl': '20px',
            'full': '9999px',
        },
        letterSpacing: {
            wide: '0.42pt',
            wider: '1pt',
            widest: '1.68pt',
        },
        minHeight: {
            '0': '0',
            '254': '254px'
        },
        extend: {
            colors: {
                color_one: '#E5291B',
                color_two: '#000000',
                color_three: '#707070',
                color_four: '#E3E8F0',
                color_five: '#F0F2F7',
                color_six: '#FFFFFF',
                color_error: '#E30000',
                color_success: '#5ad2aa',
            },
        },
    },
    variants: {},
    plugins: [
        plugin(function ({addBase, config}) {
            addBase({
                '.SelectField__indicator-separator': {display: 'none !important'},
                '.SelectField__dropdown-indicator': {color: `${config('theme.colors.color_one')} !important`},
                '.SelectFieldError__control ': {
                    borderColor: `${config('theme.colors.color_error')} !important`,
                    borderRadius: '6px !important',
                    backgroundColor: `${config('theme.colors.color_five')} !important`,
                    color: '#B9BED1 !important',
                    minHeight: '45px !important',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    cursor: 'pointer !important',
                },
                '.SelectField__control': {
                    borderRadius: '6px !important',
                    borderColor: '#E3E8F0 !important',
                    backgroundColor: `${config('theme.colors.color_five')} !important`,
                    color: '#B9BED1 !important',
                    minHeight: '45px !important',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    cursor: 'pointer !important',
                    fontSize: '14px',
                },
                '.SelectField__placeholder': {
                    color: '#a0aec0 !important',
                    fontSize: '14px',
                    overflow: 'hidden !important',
                    textOverflow: 'ellipsis !important',
                    whiteSpace: 'nowrap !important',
                },
                // '.vehicle-summary-container': {
                //     backgroundColor: `${config('theme.colors.color_one')} !important`,
                //     color: 'white',
                // },
                // '.vehicle-summary-item': {
                //     borderColor: 'white'
                // }
            })
        }),
    ]
};
