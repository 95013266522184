import React, { useContext, useEffect, useState } from "react";
import DataContext from "../data/context";
import { useHistory, useParams, useLocation, Redirect } from "react-router-dom";
import { formatPrice, getDealerFromUrl } from "../utils";
import { map } from "lodash";
import EmailConfirm from "./EmailConfirm";
import { useMutation } from "graphql-hooks";
import { LoadingIndicator } from "./indicators";
import { useTranslation } from "react-i18next";
import { useValuation } from "../hooks/useValuation";
import { fetchUserDataByCode } from "../data/session";
import analyticstracker from "analyticstracker";
import { TAGMANAGER_PAGE_ARGS } from "../tagManager";

export const confirmEmailMutation = `mutation ConfirmEmail($data: ConfirmEmailAddressType!) { 
        confirmEmail(data: $data) {
            ok,            
        }
    }`;

export const sendInspectionEmailMutation = `mutation SendInspectionEmail($data: SendInspectionMailType!) { 
        sendInspectionEmail(data: $data) {
            ok,            
        }
    }`;

const Valuation = () => {
  const {
    inspection,
    inspectionStatus,
    fetchInspection,
    inspectionError,
    dealerKey,
    dealerConfig,
    setAuthenticated,
    setField,
    inspectionWizardConfiguration,
  } = useContext(DataContext);
  const {
    valuation,
    loading: valuationLoading,
    hasValuation,
  } = useValuation(inspection);

  const { key } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [confirmMutation] = useMutation(confirmEmailMutation);
  const [sendInspectionMailMutation, { loading: resendPending }] = useMutation(
    sendInspectionEmailMutation
  );
  const [resendSuccess, setResendSuccess] = useState(false);
  const { t, i18n } = useTranslation();

  let history = useHistory();
  const showGuideValue = dealerConfig.valuationAverage;
  const searchParams = new URLSearchParams(location.search);
  let confirmKey = undefined;
  let userKey = undefined;

  if (searchParams.has("c")) {
    confirmKey = searchParams.get("c");
  }

  if (searchParams.has("u")) {
    userKey = searchParams.get("u");
  }

  useEffect(() => {
    let aTrack = analyticstracker();
    aTrack.trackImpression("page-impression");
  });

  const tagInfo = TAGMANAGER_PAGE_ARGS({
    section: "valuation",
    language: i18n.language,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (inspection.key === undefined) {
      fetchInspection(key, getDealerFromUrl(window.location.pathname));
    }
  }, [key]);

  useEffect(() => {
    // const searchParams = new URLSearchParams(location.search);
    // let confirmKey = undefined;
    // let userKey = undefined;
    //
    // if (searchParams.has("c")) {
    //     confirmKey = searchParams.get("c");
    // }
    //
    // if (searchParams.has("u")) {
    //     userKey = searchParams.get("u");
    // }

    if (confirmKey && userKey) {
      handleConfirmation(confirmKey, userKey);
    } else {
      setLoading(false);
    }
  }, []);

  const handleConfirmation = (confirmationKey, userKey) => {
    confirmMutation({
      variables: {
        data: {
          inspectionKey: key,
          confirmationKey: confirmationKey,
          userKey: userKey,
        },
      },
    }).then((result) => {
      if (result.data && result.data.confirmEmail) {
        if (result.data.confirmEmail.ok) {
          fetchUserDataByCode(dealerKey, userKey).then((user) => {
            if (user) {
              setField("userId", user.id);
              setAuthenticated(user);
              setLoading(false);
            }
          });

          history.replace({
            ...location,
            search: "",
          });
        }
      }
    });
  };

  const handleSendInspectionMail = () => {
    setResendSuccess(false);
    sendInspectionMailMutation({
      variables: {
        data: {
          inspectionKey: inspection.key,
        },
      },
    }).then((result) => {
      if (result.data && result.data.sendInspectionEmail) {
        if (result.data.sendInspectionEmail.ok) {
          setResendSuccess(true);
        }
      }
    });
  };

  if (
    (inspection.key === undefined && !inspectionError) ||
    loading ||
    (valuationLoading && inspection.userId)
  ) {
    return (
      <div className="flex flex-1 items-center justify-center w-screen h-screen">
        <LoadingIndicator />
      </div>
    );
  }

  if (inspectionError && inspection.key === undefined) {
    return (
      <Redirect
        to={`/${getDealerFromUrl(
          window.location.pathname
        )}/no-inspection-found`}
      />
    );
  }

  if (
    inspectionStatus &&
    (inspectionStatus === "COMPLETED" || inspectionStatus === "SYNCED")
  ) {
    return (
      <Redirect
        to={`/${getDealerFromUrl(window.location.pathname)}/inspection/${
          inspection.key
        }`}
      />
    );
  }

  if (inspection.userId === undefined || inspection.userId === null) {
    return <EmailConfirm />;
  }

  const renderValuationBox = (
    <div
      className={`flex flex-1 bg-color_one pt-4 rounded-sm ${
        !dealerConfig.valuation ? "hidden md:flex" : ""
      }`}
    >
      {dealerConfig.valuation && (
        <div className="flex flex-1 z-20 relative">
          <div className="flex flex-1 flex-row justify-around items-center text-center text-white font-bold px-5">
            {hasValuation && valuation.min && (
              <div className="flex-1 text-18 items-center justify-center flex-col">
                <div>{t("From")} </div>
                <div className="sm:text-20 md:text-30 font-bold">
                  {formatPrice(valuation.min)}
                </div>
              </div>
            )}
            <div className="flex-1 justify-center flex flex-col items-center">
              {t("Takeover price")}:
              <div
                className="bg-color_five text-color_one md:w-3/4 shadow-md cursor-pointer"
                onClick={() =>
                  history.push(
                    `/${getDealerFromUrl(
                      window.location.pathname
                    )}/inspection/${inspection.key}`
                  )
                }
              >
                <div>{t("Calculate precise estimation")}</div>
              </div>
              <div className="flex-1 justify-center">
                <img
                  src={require("../assets/price-indicator.svg")}
                  style={{ width: 145, marginTop: 8, bottom: 0 }}
                  alt="price-indicator"
                  className="object-center"
                />
              </div>
            </div>
            {hasValuation && valuation.max && (
              <div className="flex-1 text-18 items-center justify-center flex-col">
                <div>{t("To")} </div>
                <div className="sm:text-20 md:text-30 font-bold">
                  {formatPrice(valuation.max)}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );

  const renderStepsMobile = (
    <div className="pl-8 pr-8 mt-10 mb-10 md:hidden">
      {dealerConfig.valuation && (
        <div className="text-color_one text-24 font-bold text-center">
          {t("Want a more accurate price indication?")}
        </div>
      )}

      <div className="text-color_one text-24 font-bold text-center mt-1 mb-8">
        {t("Inspect your car yourself!")}
      </div>

      <div className="text-color_three text-center mb-8">
        <p
          className="leading-6"
          dangerouslySetInnerHTML={{
            __html: t(
              "Through our simple mobile website you can use your smartphone to inspect your car yourself in {{styling}} only 5 steps! {{stylingEnd}} It’ll only take up 15 minutes of your time and we will guide you through the whole process.",
              {
                styling: '<span class="text-color_one font-bold">',
                stylingEnd: "</span />",
                interpolation: { escapeValue: false },
              }
            ),
          }}
        ></p>

        <p className="mt-8 leading-6">
          {t(
            "With the extra information, we’ll be able to estimate an accurate takeover price."
          )}
        </p>
      </div>

      <div
        className="flex rounded-full uppercase bg-color_one text-white items-center justify-center text-center font-bold text-14 mb-4 cursor-pointer"
        style={{ minHeight: 45 }}
        onClick={() =>
          history.push(
            `/${getDealerFromUrl(window.location.pathname)}/inspection/${
              inspection.key
            }`
          )
        }
      >
        {t("Carry out the inspection yourself")}
      </div>
    </div>
  );

  const renderStepsDesktop = (
    <div
      className="pl-8 md:bg-white md:rounded-lg md:pl-12 hidden md:flex md:flex-col md:w-1/2 md:pr-0"
      style={{ maxWidth: 480 }}
    >
      {/*{dealerConfig.valuation &&*/}
      <div className="text-color_one text-24 font-bold mb-8">
        {dealerConfig.valuation ? t("A more accurate price? ") : ""}{" "}
        {t("Inspect your car yourself with your smartphone!")}
      </div>

      {/*<div className="text-color_one text-24 font-bold mb-8">{t('A more accurate price? Inspect your car yourself with your smartphone!')}</div>*/}

      <div className="text-color_three mb-5">
        {dealerConfig.valuation && (
          <p
            className="leading-6 mb-8"
            dangerouslySetInnerHTML={{
              __html: t(
                "Through our simple mobile website you can use your smartphone to inspect your car yourself in {{styling}} only 5 steps! {{stylingEnd}} With the extra information, we’ll be able to estimate an accurate takeover price. It’ll only take up 15 minutes of your time and we’ll be here to guide you through the whole process.",
                {
                  styling: '<span class="text-color_one font-bold">',
                  stylingEnd: "</span />",
                  interpolation: { escapeValue: false },
                }
              ),
            }}
          ></p>
        )}

        <div className="mt-8 mb-10">
          <div className="mb-1">{t("No QR-code scanner?")}</div>

          <div className="flex">
            {(resendSuccess || resendPending) && (
              <div className="mr-2">
                {resendSuccess ? (
                  <i className="fa fa-check text-green-500" />
                ) : resendPending ? (
                  <i className="fal fa-spinner" />
                ) : (
                  ""
                )}
              </div>
            )}
            <div
              className="text-color_one underline cursor-pointer "
              onClick={handleSendInspectionMail}
            >
              {" "}
              {t("Send me the link in an e-mail.")}
            </div>
          </div>
        </div>

        <hr className="solid border-color-five mb-10" />

        <div className="text-black text-24 font-bold">
          {t("No smartphone?")}
        </div>

        <p className="leading-6 mt-8">
          {t(
            "No problem! Do the inspection on your desktop. Just make sure you have a camera on hand to upload pictures of your car."
          )}
        </p>
      </div>

      <div className="flex">
        <div
          className="btn-primary flex rounded-full uppercase bg-white border-2 border-black text-center items-center justify-center font-bold text-12 cursor-pointer tracking-widest px-6"
          style={{ minHeight: 40 }}
          onClick={() =>
            history.push(
              `/${getDealerFromUrl(window.location.pathname)}/inspection/${
                inspection.key
              }`
            )
          }
        >
          {t("Start inspection")}
        </div>
      </div>
    </div>
  );

  const stepsPricing = [
    t("De staat van de wagen"),
    t("De uitvoering van de wagen"),
    t("Inbegrepen opties"),
    t("Datum van inlevering"),
  ];

  return (
    <div className="flex flex-1 justify-center mb-4 mt-4">
      <div
        className="md:flex flex-1 justify-center md:m-12 m-4 md:m-0 "
        data-tracking-event="page-impression"
        data-tracking-info={tagInfo}
        style={{ maxWidth: 2048 }}
      >
        <div className="flex-1 md:mr-24 mb-4">
          <div className="mb-6">
            <div className="text-color_one text-24 font-bold mb-4">
              {t("Price indication car")}
            </div>
            <p className="mb-8">
              {t("Based on your data we could make an initial pricing.")}
            </p>
            {/* TODO: no valuation found */}
            {renderValuationBox}
          </div>

          <div className="text-color_one text-24 font-bold mb-4">
            {t("Pricing")}
          </div>
          <p className="pb-2">
            {t(
              "De overnameprijs is het bedrag dat je krijgt voor je oude wagen, wanneer je bij A&M Cars een nieuwe wagen kan kopen. Opgelet! Afhankelijk van een aantal onbekende factoren zoals extra opties, schade,... kan dit bedrag nog uitwijken naar de minimum of maximum waarde."
            )}
          </p>
          <p>
            {t(
              "Om een precieze prijsbepaling vast te leggen, hebben we nood aan meerdere details"
            )}
            :
          </p>
          <div className="flex flex-col text-white mt-4">
            <div className="bg-color_two p-2 w-1/2 md:w-1/3 font-bold">
              {t("Your entered vehicledata")}
            </div>
            <div
              className="border-l-2 border-color_one flex flex-col"
              style={{ marginLeft: 18 }}
            >
              {map(stepsPricing, (value, index) => {
                return (
                  <div className="text-color_one py-4 ">
                    -
                    <span className="text-14 font-bold ml-4 text-color_one">
                      {index + 1}.{" "}
                      <span className="text-color_two">{value}</span>
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="bg-color_two p-2 w-1/2 md:w-1/3 font-bold">
              {t("The value of your vehicle")}
            </div>
          </div>
        </div>
        <div className="flex-1 ">
          <div className="flex flex-1 flex-col">
            <div className="text-color_one text-24 font-bold mb-4 ">
              {t("Make it even more accurate")}
            </div>
            <div className="mb-4">
              <p
                className="mb-4"
                dangerouslySetInnerHTML={{
                  __html: t(
                    "Via onze eenvoudige mobiele website kan je met je smartphone zelf je wagen inspecteren in {{styling}} slechts 5 stappen {{stylingEnd}}. Voorzie een 15-tal minuten van je tijd en wij begeleiden je doorheen het hele proces. Vervolgens geven we jou a.d.h.v. de extra informatie een nauwkeurigere prijsinschatting.",
                    {
                      styling: '<span class="font-bold">',
                      stylingEnd: "</span />",
                      interpolation: { escapeValue: false },
                    }
                  ),
                }}
              ></p>
              <p>
                {t(
                  "Neem alvast je smartphone bij de hand en start met de gedetailleerde inspectie."
                )}
              </p>
            </div>

            <div
              className="border-l-2 border-color_one"
              style={{ marginLeft: 18 }}
            >
              {map(inspectionWizardConfiguration, (step, i) => {
                return (
                  <div
                    key={`step${i}`}
                    className={`bg-white ${
                      i !== inspectionWizardConfiguration.length - 1
                        ? "mb-4 md:mb-6"
                        : ""
                    }`}
                  >
                    <i
                      className={`${step.icon} border-2 rounded-full border-color_one p-2 bg-white text-color_one `}
                      style={{ marginLeft: -18, fontSize: 14 }}
                    />
                    <span className="text-14 font-bold ml-4 text-color_one">
                      {i + 1}.{" "}
                      <span className="text-color_two">{step.title}</span>
                    </span>
                  </div>
                );
              })}
            </div>
            <div
              className="bg-color_one text-white p-2 rounded-sm mt-4 w-1/2 md:w-1/3 mb-4 cursor-pointer font-bold"
              onClick={() =>
                history.push(
                  `/${getDealerFromUrl(window.location.pathname)}/inspection/${
                    inspection.key
                  }`
                )
              }
            >
              {t("Do the full self-check")}
            </div>
            <div className="bg-color_two flex-1 flex-col flex p-6 text-color_five">
              <div className=" text-24 font-bold mb-4">
                {t("Value together")}
              </div>
              {t(
                "Maak een afspraak in je favoriete vestiging en we overlopen samen de stappen om je correcte prijsbepaling neer te zetten."
              )}
              <div
                className="bg-color_one text-white p-2 font-bold rounded-sm mt-4 mb-4 w-1/2 md:w-1/3 cursor-pointer"
                onClick={() => (window.location.href = "info@amcars.be")}
              >
                <p>{t("Make your appointment")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Valuation;
